const AnimatedPath = ({ values = "" }) => {
  return (
    <path
      d=""
      className="stroke-[4px] stroke-sky-50 fill-sky-500"
      fill="transparent"
      stroke="url(#lg-0.7838540307436188)"
      opacity="0.4"
    >
      <animate
        attributeName="d"
        dur="7s"
        repeatCount="indefinite"
        keyTimes="0;0.333;0.667;1"
        calcMode="spline"
        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
        begin="0s"
        values={values}
      ></animate>
    </path>
  );
};

export const AnimatedBackground = () => {
  return (
    <svg
      className="bg-sky-400 h-full w-full object-cover"
      viewBox="0 0 1280 1334"
    >
      <g>
        <linearGradient id="lg-0.7838540307436188" x1="0" x2="1" y1="0" y2="0">
          <stop stopColor="#222" offset="0"></stop>
          <stop stopColor="#222" offset="1"></stop>
        </linearGradient>
        <AnimatedPath values="M0 0M 0 1157.6452332296772Q 128 1106.2168755252608 256 1104.0956413104375T 512 1149.4906256341178T 768 1020.5368390775485T 1024 979.7099606320779T 1280 1008.3007645791313L 1280 446.7385340222544Q 1152 368.17004596793544 1024 359.4995675557323T 768 337.3642520993528T 512 278.2553200575422T 256 195.14654989321002T 0 61.913960260201236Z;M0 0M 0 1241.0464587251513Q 128 1212.2181551883025 256 1209.1884533229952T 512 1166.1489730341211T 768 1120.9354765243186T 1024 1074.152802761616T 1280 907.7571112181989L 1280 382.48158514909835Q 1152 323.5139669855365 1024 316.61427553633087T 768 326.0206409668915T 512 241.1258461449127T 256 168.89563737162268T 0 129.8949333050312Z;M0 0M 0 1271.8652863487591Q 128 1160.536031424174 256 1151.3001037913127T 512 1034.1344926473432T 768 1041.9153125145974T 1024 1080.7710941762239T 1280 903.7219588580303L 1280 307.5607632028953Q 1152 277.5472724000996 1024 274.6007492490102T 768 332.14914147198635T 512 214.46128633643463T 256 207.75963181657937T 0 80.09152111436373Z;M0 0M 0 1157.6452332296772Q 128 1106.2168755252608 256 1104.0956413104375T 512 1149.4906256341178T 768 1020.5368390775485T 1024 979.7099606320779T 1280 1008.3007645791313L 1280 446.7385340222544Q 1152 368.17004596793544 1024 359.4995675557323T 768 337.3642520993528T 512 278.2553200575422T 256 195.14654989321002T 0 61.913960260201236Z" />
        <AnimatedPath values="M0 0M 0 1226.315883806146Q 128 1215.672139720023 256 1215.4275868317688T 512 1138.6324153498174T 768 1060.146005834654T 1024 1061.68554362214T 1280 943.2426551595549L 1280 388.5632603833285Q 1152 309.42356312103254 1024 305.52778017917575T 768 305.98698506890094T 512 168.76502071008593T 256 145.93602827343148T 0 100.56634177780506Z;M0 0M 0 1261.926653460271Q 128 1203.9939362568641 256 1196.9253977111014T 512 1029.0380819783109T 768 1098.202742669825T 1024 1047.5292328938315T 1280 1023.8036217947706L 1280 432.66754677279675Q 1152 352.59201122180843 1024 345.5214599326234T 768 230.99422622526052T 512 199.847555517425T 256 164.21190078105673T 0 127.32559333736958Z;M0 0M 0 1168.8679173855824Q 128 1182.502822832465 256 1175.627984583333T 512 1155.1056004225295T 768 1033.5871522047157T 1024 944.0635333494857T 1280 988.228932598927L 1280 393.15041506622765Q 1152 257.2792813046558 1024 249.21722707084763T 768 236.29268799049018T 512 248.57727372195245T 256 227.790825826821T 0 128.92785038627989Z;M0 0M 0 1226.315883806146Q 128 1215.672139720023 256 1215.4275868317688T 512 1138.6324153498174T 768 1060.146005834654T 1024 1061.68554362214T 1280 943.2426551595549L 1280 388.5632603833285Q 1152 309.42356312103254 1024 305.52778017917575T 768 305.98698506890094T 512 168.76502071008593T 256 145.93602827343148T 0 100.56634177780506Z" />
        <AnimatedPath values="M0 0M 0 1189.2936393142795Q 128 1217.994198850639 256 1211.7120970972092T 512 1028.782312476161T 768 986.1172471038924T 1024 1081.5637319422858T 1280 901.6563413500692L 1280 351.2962658153776Q 1152 259.4754066908416 1024 250.8684170472091T 768 315.71971359040595T 512 189.15340742337833T 256 118.55547971371516T 0 171.523807242734Z;M0 0M 0 1185.1722832485Q 128 1110.060919272062 256 1109.0022168286916T 512 1129.5182337062417T 768 1010.3035569112815T 1024 977.694989579761T 1280 967.9187641492156L 1280 297.32915009889257Q 1152 346.9378434686258 1024 337.69413588829127T 768 251.329991885483T 512 299.6540797074179T 256 133.49644771693062T 0 117.73618534178678Z;M0 0M 0 1149.9341274218818Q 128 1161.0075148944707 256 1160.9777759981441T 512 1085.8956530136888T 768 1111.4945516184182T 1024 1029.1652923991305T 1280 954.7906677937408L 1280 435.8065888933536Q 1152 296.4973919059214 1024 292.22230427105814T 768 302.06107627441406T 512 234.80703123501445T 256 205.9433461968453T 0 84.60891487067084Z;M0 0M 0 1189.2936393142795Q 128 1217.994198850639 256 1211.7120970972092T 512 1028.782312476161T 768 986.1172471038924T 1024 1081.5637319422858T 1280 901.6563413500692L 1280 351.2962658153776Q 1152 259.4754066908416 1024 250.8684170472091T 768 315.71971359040595T 512 189.15340742337833T 256 118.55547971371516T 0 171.523807242734Z" />
        <AnimatedPath values="M0 0M 0 1175.4438128670045Q 128 1183.2712521359801 256 1175.2921839312678T 512 1119.234554239677T 768 1052.416788162799T 1024 932.5499533793285T 1280 961.585044175509L 1280 406.5174831761249Q 1152 305.3448034499986 1024 304.1204265305276T 768 250.46175390322813T 512 235.66726541447554T 256 136.38798250390494T 0 202.59671788480904Z;M0 0M 0 1166.1375232513017Q 128 1079.756715334422 256 1076.878417789118T 512 1182.1651299029372T 768 1135.5621229800622T 1024 971.2543820521258T 1280 898.3166019087942L 1280 418.5092241953463Q 1152 258.0687167228914 1024 255.0874862406024T 768 315.0791235489454T 512 266.84861339839784T 256 248.93329174419813T 0 173.05784020858204Z;M0 0M 0 1199.4608547843006Q 128 1083.6409548779645 256 1082.2674936122003T 512 1062.2538127152845T 768 1001.425586411065T 1024 959.1872137774376T 1280 888.1921807594975L 1280 339.1786409341723Q 1152 311.007100002942 1024 302.8021523511055T 768 344.4151421622789T 512 189.7656215807491T 256 153.8383775601755T 0 85.91150055498662Z;M0 0M 0 1175.4438128670045Q 128 1183.2712521359801 256 1175.2921839312678T 512 1119.234554239677T 768 1052.416788162799T 1024 932.5499533793285T 1280 961.585044175509L 1280 406.5174831761249Q 1152 305.3448034499986 1024 304.1204265305276T 768 250.46175390322813T 512 235.66726541447554T 256 136.38798250390494T 0 202.59671788480904Z" />
      </g>
    </svg>
  );
};
